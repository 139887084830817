import { ReactNode, useMemo } from "react";
import toast from "react-hot-toast";

import { Container } from "@/components/base/container";

import { OnboardingCard } from "./onboarding-card";
import { OnboardingProgressBar } from "./onboarding-progress-bar";
import {
  ONBOARDING_INTRO_MODAL_ID,
  OnboardingIntroModal,
} from "@/components/modals/onboarding-intro-modal";
import { modal } from "@/components/modals";
import { useGlobalContext } from "@/hooks/use-global-context";
import { UserOnboardingI } from "@/interfaces/user";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useOnboarding } from "@/hooks/use-onboarding";
import Router from "@/helpers/router";
import { useApi } from "shared/lib/hooks";
import {
  ONBOARDING_CAMPAIGNS_MODAL_ID,
  OnboardingCampaignsModal,
} from "@/components/modals/onboarding-campaigns-modal";

const createStep = (
  title?: string,
  subTitle?: ReactNode,
  imgSrc?: string,
  onClick?: () => void
) => ({
  title,
  subTitle,
  imgSrc,
  onClick,
});

export const OnboardingModule = () => {
  const { glencocoUser: user, reloadUser } = useGlobalContext();
  const { step } = useOnboarding();
  const { user_onboarding_status } = user || {};
  const { watched_intro_video, picked_campaign } = user_onboarding_status || {};

  const atsCampaignFetcher = useMemo(
    () =>
      user?.ats_campaign_id
        ? (api: APII) => api.getCampaign(user.ats_campaign_id as string)
        : null,
    [user?.ats_campaign_id]
  );

  const [{ data: GetAtsCampaignResponse }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: atsCampaignFetcher,
    shouldCallAutomatically: true,
    onError: () =>
      toast.error(
        "Failed to retrieve your recommended campaign. Please message us in the chat window on the bottom right so we can assist."
      ),
  });

  const enrollIntoCampaign = async (campaignId?: string) => {
    if (!campaignId) {
      return false;
    }

    const API = glencocoClientAPI();
    const resp = await API.training.enrollIntoCampaignTraining(campaignId);

    if (resp.status === 200) {
      return true;
    } else {
      toast.error(
        "Failed to enroll into campaign. Please message us in the chat window on the bottom right so we can assist."
      );
      return false;
    }
  };

  const disabled = useMemo(() => {
    return [false, !watched_intro_video];
  }, [watched_intro_video]);

  const isCompleted = useMemo(() => {
    return [watched_intro_video, picked_campaign];
  }, [watched_intro_video, picked_campaign]);

  const updateOnboarding = async (params?: UserOnboardingI) => {
    const API = glencocoClientAPI();

    const UpdateOnboardingResponse = await API.updateOnboardingStatus(
      params
    ).catch((e) => e);

    if (UpdateOnboardingResponse?.status === 200) {
      await reloadUser?.();

      return true;
    } else {
      toast.error("Failed to update onboarding status");

      return false;
    }
  };

  const handleIntroModal = async () => {
    if (!watched_intro_video)
      await updateOnboarding({ watched_intro_video: true });
  };

  const handleStartCampaign = async () => {
    const campaignEnrollSuccess = await enrollIntoCampaign(
      user?.ats_campaign_id
    );

    if (campaignEnrollSuccess) {
      await updateOnboarding({ picked_campaign: true });

      window.location.href = Router.training(
        GetAtsCampaignResponse?.campaign.id
      );
    }
  };

  const steps = useMemo(
    () => [
      createStep(
        "Introduction to the Glencoco platform video",
        "Get an introduction to the platform and learn about the tools that will help you reach your earning goals",
        "https://glencoco-assets.s3.amazonaws.com/platform/onboarding/user-onboarding-intro.png",
        () => {
          modal.trigger(ONBOARDING_INTRO_MODAL_ID);
        }
      ),
      createStep(
        "Start your first campaign",
        <>
          We've recommended the{" "}
          <b>{GetAtsCampaignResponse?.campaign?.name || ""}</b> campaign for
          you. Click below to start training or explore other campaigns.
        </>,
        GetAtsCampaignResponse?.campaign.logo_s3_url ||
          "https://glencoco-assets.s3.amazonaws.com/platform/onboarding/user-onboarding-fcampaign.png",
        () => {
          modal.trigger(ONBOARDING_CAMPAIGNS_MODAL_ID);
        }
      ),
    ],
    [GetAtsCampaignResponse?.campaign]
  );

  return (
    <>
      <Container className="!max-w-[1085px]">
        <section>
          <OnboardingProgressBar className="mb-10" step={step} />

          <div className="mb-8">
            <h1 className="b-typography-h3 mb-1">Get to know Glencoco</h1>
            <p className="brand-typography-body3">
              Complete each intro section to understand how Glencoco is helping
              sales professionals earn money making calls
            </p>
          </div>

          <div>
            {steps.map((step, i) => (
              <OnboardingCard
                {...step}
                key={i}
                className="mb-3"
                disabled={disabled[i]}
                isCompleted={isCompleted[i]}
              />
            ))}
          </div>
        </section>
      </Container>

      <OnboardingIntroModal onSuccess={handleIntroModal} />
      <OnboardingCampaignsModal onSuccess={handleStartCampaign} />
    </>
  );
};
